
@import '~www/themes/doctors/common/variables';

.confirm-town {
  position: fixed;
  z-index: 100;
  padding: 0;

  &_desktop {
    display: flex;
    justify-content: flex-end;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }

  &_mobile {
    bottom: 16px;
    left: 20px;
    right: 20px;
  }

  &__banner {
    pointer-events: initial;
  }
}
