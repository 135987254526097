@import '~www/themes/doctors/common/variables';

.adminka {
  margin: 0 0.3em;
  font-size: 14px;
  text-decoration: underline;
  border: 0;
  cursor: pointer;
  background-color: yellow;

  &:hover,
  &:focus {
    color: $ui-kit-secondary;
  }

  &_absolutely-positioned {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    margin: 0;
    padding: 4px 8px;
  }

  &_no-background {
    background-color: transparent;
  }
}

.adminka-box {
  padding: 20px;
  border-radius: $border-radius-sm;
  background-color: yellow;
}

.adminka-box input,
.adminka-input {
  padding: 4px 8px;
  background: $ui-kit-bg-gray-0;
  border: 1px solid $ui-kit-bg-gray-80;
  border-radius: $border-radius-sm;
}

.adminka-btn,
.adminka-select {
  border-radius: 3px;
  padding: 1px 6px;
  border: 1px solid gray;
  background-color: whitesmoke;
}

.adminka-select {
  appearance: auto;
}
