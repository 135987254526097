.user-menu-top {
  position: absolute;
  right: 0;
  width: 215px;
  height: 45px;
  top: 5px;
  box-sizing: border-box;
  font-size: 1.2em;
  background-color: white;
  z-index: 100;
  padding-top: 5px;
}

.user-menu-top__info > div {
  padding-right: 20px;
}

.user-menu-top > div:not(.user-menu-top__name) {
  padding: 0 20px 0 0;
}

.user-menu-top ul li {
  padding-left: 20px;
}

.user-menu-top:not(.opened):hover {
  opacity: 0.5;
  cursor: pointer;
}

.user-menu-top__patient-name {
  text-align: right;
}

.user-menu-top:not(.opened) .user-menu-top__patient-name {
  background: #FFFFFF url('/static/_v1/pd/icons/arrows/drop-down-menu.png') no-repeat scroll right center;
}

.user-menu-top.opened {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  height: 200px;
  text-align: left;
  box-shadow: 0 7px 13px #AAAAAA;
  width: 230px;
}

.user-menu-top:not(.opened) > div:last-child {
  display: none;
}

.user-menu-top:not(.opened) .user_menu_lpu_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.user-menu-top__prof ul {
  margin: 0;
  padding: 10px;
  list-style: none;
  font-size: 12px;
  line-height: 20px;
}

.user-menu-top__page > a {
  color: #0091FF;
}

/* stylelint-disable selector-id-pattern */
.user_menu_elem > div.user-menu-top__prof {
  text-align: left;
  z-index: 1000;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  display: none;
  background-color: white;
  border: 1px solid #CCCCCC;
  box-shadow: 0 7px 13px #AAAAAA;
}

#user_menu_rates > div.user-menu-top__prof {
  width: 250px;
  padding: 10px 0;
  overflow: hidden;
}

/* stylelint-enable selector-id-pattern */

.user-menu-top__avatar {
  vertical-align: middle;
  max-width: 32px;
  border-radius: 16px;
}

.user-menu-top__name {
  overflow: hidden;
  text-align: left;
}

.user-menu-top__page {
  background: url('/static/_v1/pd/icons/arrows/link-arrow-in-square.png') no-repeat;
}

.user-menu-top__bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.user-menu-top__doctor {
  text-align: right !important;
}

.user-menu-top__lpu.opened {
  width: 300px;
}

.user-menu-top__info {
  display: inline-block;
  max-width: 170px;
  padding-right: 0 !important;
  margin-left: 5px;
}
