@import '~www/themes/doctors/common/variables';

.b-section {
  font-family: $font-family-regular;
  font-style: normal;
  margin: 40px 0 0;

  &__body {
    &_row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }

  &__header {
    margin: 0 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: $weight-medium;
    font-size: $font-size-xl;
    color: $color-neur-dark;
  }

  &__more-link {
    font-style: normal;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;

    &:hover {
      text-decoration: none;
      color: $ui-kit-text-secondary;
    }
  }
}
