#body_div {
    top: 0;
    position: relative;
}

.login_links {
    float: right;
    line-height: 30px;
    margin-top: -40px;
    font-size: 1.1em;
}

.login_links a {
    margin-right: 25px;
}

.towndiv a {
    text-decoration: none;
}

.user_line {
    margin-top: 0 !important;
}

#menu_line {
    box-shadow: 0 40px 50px -10px rgba(0,0,0,.05);
    height: 41px;
    background: white;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
    z-index: 99999;
    padding-top: 5px;
}

#nav2 {
    margin: 0 auto;
    width: 980px;
    height: 30px;
    display: block;
    /*background-color: rgb(0, 145, 255);*/
    border-radius: 4px;
    padding: 5px 5px 0px 15px;
    position: relative;
    right: 9px;
}

#nav2 > li:hover {
    visibility: visible;
    opacity: 1;
}

#nav2 > li.finddiv, #nav2 > li.pharm_search {
    float: right;
}

#nav2 > li {
    float: left;
    position: relative;
    list-style: none;
    display: block;
}

.pharm_tags_cols .cnt, .town_vrach_speclist .cnt, .town_link .cnt {
    color: #0091ff;
    font-size: 12px;
    margin-left: 5px;
}

#nav2 .cnt {
    color: gray;
    font-size: 12px;
    margin-left: 5px;
}

#nav2 > li:hover .dropdown {
    position: absolute !important;
    left: 0;
    top: 26px;
    visibility: visible;
    opacity: 1;
}

#nav2 > li.no_menu:not(.active) > a:hover {
    color: #0091ff !important;
    height: 25px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
}

#nav2 > li.active nav {
    margin-top: -1px;
}

#nav2 > li:hover > a {
    text-decoration: none;
    border-width: 1px 1px 0;
    position: relative;
    z-index: 99;
    color: white;
}

#nav2 > li:not(:last-child) {
    float: left;
    position: relative;
    list-style: none;
    font-size: 1.2em;
    display: block;
    border: none;
}

#nav2 > li > a {
    font-weight: 500;
    color: #0091ff;
    display: block;
    margin: 0;
    z-index: 51;
    outline: 0;
    text-decoration: none;
    padding-right: 18px;
    position: relative;
}

#nav2 > li:not(:last-child) > a {
    padding: 0 10px;
}

#nav2 > li:not(.finddiv) > a {
    border: 1px solid white;
    line-height: 25px;
    color: #0091ff;
    font-size: 18px;
    cursor: pointer;
    top: 1px;
}

#nav2 > li.active > a {
    height: 30px;
    background-color: white;
    color: #0091ff !important;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#nav2 > li:not(.finddiv) > a {
    font-size: 14px;
}

#my_reviews ul {
    list-style: none;
    /*padding-left: 0;*/
    padding: 0;
}

#menu_icon {
    height: 30px;
    margin-right: 5px;
    float: left;
    position: relative;
}

#menu_icon > a {
    cursor: pointer;
    color: black;
    display: inline-block;
    height: 30px;
}

#menu_icon:hover .dropdown.proflist {
    position: absolute !important;
    left: 0;
    top: 20px;
    visibility: visible;
    opacity: 1;
    width: 980px !important;
}

#menu_profile {
    float: right !important;
}

#menu_doctor_div {
    width: 700px;
    height: auto;
}

#menu_line .menu_diagnostika_div {
    width: 200px;
    height: 300px;
}

#menu_line .menu_services_div {
    height: 350px;
    width: 690px;
}

#menu_line .menu_doctor_stoma_div {
    width: 300px;
}

#menu_line .menu_doctor_child_div {
    width: 500px;
}

#menu_line .menu_consult_div {
    height: 410px;
}

#menu_line .menu_pharm_manuf_div {
    height: 600px;
    width: 700px;
}

#menu_line .menu_pharm_manuf_div a {
    font-size: 12px;
    line-height: 12px !important;
}

#menu_change_section_div {
    width: 150px;
    height: 170px;
    margin-left: -10px;
}

#menu_profile_div {
    width: 450px;
    height: 270px;
    float: right;
    position: absolute;
    right: 0;
    left: auto;

}

#menu_line .menu_lpu_div {
    width: 300px;
    height: 350px;
}

#menu_line .menu_lpu_gos_div {
    width: 500px;
    height: 250px;
}

#menu_lpu_div {
    width: 500px;
    height: 350px;
}

#menu_lpu_div ul ul li {
    width: 400px;
}

#menu_diagnostika_div {
    height: 265px;
    width: 500px;
    padding: 10px 0 0 5px;
}

#town_speclist {
    height: 240px;
}

#town_services {
    height: 265px;
}

#town_lputypes {
    height: 270px;
}

.town_vrach_all > ul > li, #town_speclist > ul > li, #town_services > ul > li, #town_lputypes > ul > li {
    max-width: 200px;
}

#menu_diagnostika_div ul, .town_diagnostics ul {
    white-space: nowrap;
    padding-right: 10px;
}

#menu_change_section_div, #menu_line .menu_hoverable_div, #menu_doctor_div, #menu_lpu_div, #menu_diagnostika_div, #menu_profile_div {
    display: none;
    position: absolute;
    z-index: 15;
    background: white;
}

.nav {
    height: 300px;
    display: none;
    z-index: 15;
    background: white;
    margin: 0 auto;

    position: relative;
    border: 1px solid #ccc;

    -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .25);
}

.nav > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav > ul > li {
    padding: 0;
    border-bottom: 1px solid #fff;
    display: block;
}

.nav > ul > li > a {
    text-decoration: none;
}

.nav > ul > li a {
    line-height: 24px;
    text-decoration: none;
}

.nav ul ul {
    display: none;
    position: absolute;
    top: 20px;
    left: 200px;
    border-left: 1px solid #ccc;
    height: 92%;
    list-style: none;
}

#nav2 .nav ul {
    padding: 15px;
}

#menu_change_section_div ul {
    padding-left: 25px;
    top: 10px;
}

.menu_services_div ul, .menu_diagnostika_div ul {
    padding-left: 0;
}

.menu_services_div ul, .menu_lpu_gos_div ul {
    position: static;
    float: left;
}

.menu_services_div ul li, .menu_diagnostika_div ul li {
    padding-left: 45px;
    background-size: 30px auto;
    line-height: 40px;
    margin-left: 10px;
}

#menu_line .menu_nopadding {
    padding-left: 0;
}

#menu_diagnostika_div.nav > ul > li > a, .town_diagnostics > ul > li > a {
    padding: 0.5em 0.2em 0.5em 1em;
    display: inline-block;
}

#menu_line .menu_doctor_adult_div ul, #menu_line .menu_consult_div ul, #menu_line .menu_pharm_div ul, #menu_doctor_div ul ul, .town_vrach_all ul ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

#menu_line .menu_pharm_manuf_div ul {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
}

#menu_line .menu_doctor_child_div ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

#menu_line .menu_pharm_div > ul {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
}

#menu_line .menu_pharm_div {
    height: auto;
}

.menu_pharm_div > ul > li > a {
    display: inline;
}

#menu_lpu_div > ul > li > a, #menu_doctor_div > ul > li > a {
    line-height: 40px;
}

.town_links a {
    line-height: 20px;
    text-decoration: none;
}

.town_link a, .town_link .cnt {
    font-size: 14px;
}
