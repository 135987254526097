.b-table {
  border-collapse: collapse;
  margin: 10px 0;

  & td,
  & th {
    border: 1px solid #CCCCCC;
    padding: 5px;
  }
}
