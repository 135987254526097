@import '~www/themes/doctors/common/variables';

.b-box {
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;

  &_shadow {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }

  &_padding {
    &_normal {
      padding: 32px;
    }

    &_small {
      padding: 24px;
    }

    &_smallest {
      padding: 8px;
    }
  }
}
