@import '~www/themes/doctors/common/variables';

.b-articles-list {
  font-family: $font-family-regular;
  font-style: normal;

  &__header {
    color: $color-neur-dark;
  }

  &__title {
    margin-bottom: 0;
    font-weight: $weight-medium;
    font-size: $font-size-xl;
    line-height: 29px;
  }

  &__description {
    margin: 16px 0 0;
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
  }

  &__body {
    margin: 32px 0 0;
  }

  &__item {
    margin: 0 0 28px;
    font-family: $font-family-regular;
    font-style: normal;
  }

  &__item-title {
    margin: 0 0 16px;
    font-weight: $weight-medium;
    font-size: 20px;
    line-height: 23px;
  }

  &__item-link {
    color: $ui-kit-primary;
    text-decoration: none;
  }

  &__item-description {
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
  }
}
