@import '~www/themes/doctors/common/variables';

.b-feed {
  font-family: $font-family-regular;
  font-style: normal;

  &__item {
    margin: 8px 0;
    display: flex;
    flex-flow: column nowrap;

    &_hidden {
      display: none;
    }
  }

  &__header {
    padding: 11px 24px 11px 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: $color-ultra-light-grey;
    order: 2;

    &_no-back {
      padding: 0 11px;
      background: transparent;
    }
  }

  &__author {
    display: flex;
    flex-flow: row nowrap;
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
  }

  &__author-name {
    font-weight: $weight-medium;
    text-decoration: none;
    color: $color-neur-dark;
  }

  &__author-link {
    text-decoration: none;

    &:hover {
      text-decoration-line: underline;
      color: $ui-kit-primary;
    }
  }

  &__author-det {
    color: $ui-kit-text-secondary;
  }

  &__avatar-wrap {
    width: 37px;
    height: 37px;
    margin: 0 8px 0 0;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__category {
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
  }

  &__category-link {
    margin: 0 0 0 14px;
    font-weight: $weight-medium;
    color: $ui-kit-primary;

    &:hover {
      text-decoration: none;
      color: $ui-kit-primary;
    }
  }

  &__body {
    margin: 22px 0 8px;
    padding: 0 16px;
    order: 3;

    &_bubble {
      margin: 0 0 24px;
      padding: 24px;
      background: $ui-kit-bg-gray-0;
      box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
      border-radius: 5px;
      order: 1;
      position: relative;

      &::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 8px solid white;
        border-right: 11px solid transparent;
        position: absolute;
        left: 28px;
        bottom: -8px;
        border-radius: 0 0 0 3px;
      }
    }

    &_simple {
      height: 90px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      align-items: stretch;
      background: $ui-kit-bg-gray-0;
      border: 1px solid $color-ultra-deep-grey;
      border-radius: 5px;
      overflow: hidden;
      order: 3;
    }
  }

  &__number {
    &_simple {
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-ultra-light-grey;
      border-radius: 0 5px 5px 0;
      font-weight: $weight-medium;
      font-size: $font-size-body-1;
      line-height: $line-height-base;
      color: $color-dark-grey;
    }
  }

  &__title {
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-primary;

    &_simple {
      flex: 1 1 auto;
      padding: 24px;
      display: flex;
      align-items: center;
    }
  }

  &__link {
    width: 100%;
    text-decoration-line: none;
    color: $ui-kit-primary;

    &:hover {
      text-decoration-line: underline;
      color: $ui-kit-primary;
    }
  }

  &__discription {
    margin: 10px 0 0;
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
  }

  &__expand {
    margin: 16px 0;
    padding: 18px;
    background: $ui-kit-bg-primary;
    border-radius: 5px;
    cursor: pointer;
  }

  &__expand-message {
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    text-align: center;
    color: $ui-kit-primary;

    &_bold {
      font-weight: $weight-medium;
    }
  }

  &__ellip-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    text-decoration: inherit;
  }

  &__ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    text-decoration: inherit;
  }

  &__footer {
    order: 10;
    margin: 0 0 8px;
    padding: 0 24px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  &__date {
    font-weight: $weight-regular;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
  }
}
