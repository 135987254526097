@import '~www/themes/doctors/common/variables';

.b-breadcrumbs {
  width: auto;
  color: $ui-kit-text-secondary;
  font-family: $font-family-regular;

  &__item {
    margin: 0 8px;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }
  }

  &__item-link {
    color: $ui-kit-text-secondary !important;
    font-size: $font-size-body-1;
    transition: 0.2s ease-in-out;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &__hidden-menu {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
  }

  &__hidden-menu-btn {
    background: #E8EAEB;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #E8EAEB;
    display: flex;
    justify-content: center;
    outline: none;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;

    span {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      margin-left: 3px;

      &:last-child {
        margin-right: 3px;
      }
    }

    &_is_active {
      background: #DCE6EB;
      border-color: #ACBFCB;

      &:hover {
        background: #DCE6EB;
      }
    }
  }

  &__hidden-menu-inner {
    display: none;
    position: absolute;
    background: $ui-kit-bg-gray-0;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: left;
    margin-top: 8px;

    &_is_show {
      display: block;
      z-index: 1000;
    }
  }

  &__hidden-menu-inner &__item {
    display: block;
    margin: 0;
    border-bottom: 1px solid #E8EAEB;
    min-width: 230px;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__hidden-menu-inner &__item-link {
    display: block;
    padding: 16px;
    color: $color-dark-grey !important;
    text-decoration: none !important;

    &:hover {
      background: $color-ultra-light-grey;
    }
  }
}
