.header-top {
  background-color: #F5F5F5;
  border-bottom: 1px solid #DDDDDD;
}

.header-top_debug {
  background-color: #DCFFA5;
}

.header-top__content {
  margin: 0 auto;
  height: 20px;
  width: 1000px;
  position: relative;
  color: black;
}

.header-top__link {
  color: black;
  line-height: 20px;
  text-decoration: none;
  margin-right: 25px;
}

.header-top__link:hover {
  text-decoration: underline;
}

.header-top__link_current {
  font-weight: 500;
  color: red;
}
