@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

.b-intro {
  background: $ui-kit-bg-gray-0;
  padding: 40px 0;
  font-family: $font-family-regular;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);
  padding-bottom: 80px;

  &__header {
    text-align: center;
  }

  &__title {
    color: $color-neur-dark;
    font-size: $font-size-xxl;
    font-weight: $weight-medium;

    span {
      font-size: 20px;
      color: $ui-kit-text-secondary;
    }
  }

  &__subtitle {
    color: $ui-kit-text-secondary;
    font-size: $font-size-body-1;
    line-height: 1.3em;
  }

  &__body {
    padding-top: 60px;

    &_padding_small {
      padding-top: 18px;
    }
  }

  &__search-wrapper {
    transform: translateY(50%);
    margin-bottom: 70px;
    z-index: 100;
    position: relative;
  }

  &__stat {
    display: flex;
    margin: 52px -16px 0;
    justify-content: center;
  }

  &__stat-item {
    padding-right: 9px;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }

  &__stat-item-img {
    width: 60px;
    height: 60px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px auto;

    &_type {
      &_reviews {
        background-image: url('/static/_v1/pd/icons/advantages/review.svg');
      }

      &_appointment {
        background-image: url('/static/_v1/pd/icons/advantages/appointment.svg');
      }

      &_doctor {
        background-image: url('/static/_v1/pd/icons/advantages/doctor.svg');
      }

      &_lpu {
        background-image: url('/static/_v1/pd/icons/advantages/lpu.svg');
      }

      &_users {
        background-image: url('/static/_v1/pd/icons/advantages/group-users.svg');
      }
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 16px;
    }
  }

  &__stat-item-content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 4px;
    border-right: 1px solid #E8EAEB;
    padding-right: 24px;
    box-sizing: border-box;
    width: calc(100% - 60px);

    &_centred {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      padding-left: 0;
      padding-right: 32px;

      &:last-child {
        padding-right: 0;
      }
    }

    &:last-child {
      border: 0;
    }

    &_no-border {
      border: 0;
    }

    @include media-breakpoint-down(lg) {
      padding-left: 0;
    }
  }

  &__stat-item-num {
    color: $color-neur-dark;
    font-size: $font-size-xl;
    font-weight: $weight-medium;
  }

  &__stat-item-name {
    font-size: $font-size-body-1;
    color: $ui-kit-text-secondary;
    margin-bottom: 6px;
  }

  &__stat-item-footer {
    margin-top: 11px;
  }

  &__stat-item-week-label {
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-body-1;
    color: $ui-kit-text-secondary;
    margin-right: 8px;
  }

  &__stat-item-week-num {
    display: inline-block;
    padding: 5px 12px;
    border: 2px solid $color-neur-green;
    color: $color-neur-green;
    font-weight: $weight-medium;
    border-radius: $border-radius-sm;
    margin-left: 64px;
    line-height: 1em;
    height: 28px;
    box-sizing: border-box;
  }

  &_no-padding-bottom {
    padding-bottom: 0;
  }

  &__notification {
    margin: 0 0 38px;
  }
}
